body {
  background-color: rgba(0, 0, 0, 0.2);
  background-image: url("./Assets/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#header {
  background-color: #fcb804;
  border-radius: 0px 0px 20px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 30vh;
  z-index: 1;
  font-size: 4vh;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

#title {
  font-size: 4vh;
  transition-duration: 0.6s;
}

#subtitle {
  font-size: 2vh;
  transition-duration: 0.6s;
}

#logo {
  border-radius: 50%;
  -webkit-box-shadow: 0px 10px 21px 8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 10px 21px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 21px 8px rgba(0, 0, 0, 0.3);
  height: 15vh;
  transition-duration: 0.5s;
  margin-top: 1vh;
}

#logosm {
  border-radius: 50%;
  -webkit-box-shadow: 0px 10px 21px 8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 10px 21px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 21px 8px rgba(0, 0, 0, 0.3);
  height: 50px;
}

#btn-list {
  margin-top: 38vh;
  font-size: 8vw;
}

#menuBtn {
  transition-duration: 0.5s;
}

#btn-icon {
  position: relative;
  float: left;
}

.bowl {
  padding-top: 1vh;
}

.delivery {
  background-color: #fcb804;
  font-size: 30px;
  font-size: 7vw;
  padding-bottom: 0px;
}

#btn-title {
  color: black;
}

#wifi {
  background-color: #fcb804;
  color: black;
  padding: 10px;
  font-size: 2vh;
  -webkit-box-shadow: 0px 10px 21px 8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 10px 21px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 21px 8px rgba(0, 0, 0, 0.3);
}

#entradas {
  background-image: url(./Assets/entradas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 20%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

#tjelas {
  background-image: url(./Assets/tjelas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 2vh;
}

#sandwishs {
  background-image: url(./Assets/sandwishs.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 2vh;
}

#smootjelas {
  background-image: url(./Assets/smootjelas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 65%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 2vh;
}

#doces {
  background-image: url(./Assets/doces.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 45%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 2vh;
}

#bebidas {
  background-image: url(./Assets/bebidas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 2vh;
}

#brunch {
  background-image: url(./Assets/brunch.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 2vh;
}

#cafe {
  background-image: url(./Assets/cafe.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 70%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 2vh;
}

#coffedentialLogo {
  height: 7vh;
}

#ovostostas {
  background-image: url(./Assets/ovostostas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 5vw;
  line-height: 5;
}

#panquecas {
  background-image: url(./Assets/panquecas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 80%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 5vw;
  line-height: 5;
}

#coffeedential {
  background-image: url(./Assets/coffeedential.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 5vw;
  line-height: 5;
}

#filtro {
  background-image: url(./Assets/filtro.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 5vw;
  line-height: 5;
}

#especiais {
  background-image: url(./Assets/especiais.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  font-size: 5vw;
  line-height: 5;
}

#guacamole {
  object-fit: contain;
  background-image: url(./Assets/guac.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
}

#gyozas {
  object-fit: contain;
  background-image: url(./Assets/gyozas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
}

#totopos {
  object-fit: contain;
  background-image: url(./Assets/entradas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 20%;
}

#tjela-frango {
  object-fit: contain;
  background-image: url(./Assets/tjela-frango.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 30%;
  position: relative;
}

#tjela-porco {
  object-fit: contain;
  background-image: url(./Assets/tjelaBBQ.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  position: relative;
}

#tjela-salmao {
  object-fit: contain;
  background-image: url(./Assets/pokesalmao.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 35%;
  position: relative;
}

#tjela-atum {
  object-fit: contain;
  background-image: url(./Assets/tjela-atum.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  position: relative;
}

#tjela-veg {
  object-fit: contain;
  background-image: url(./Assets/tjela-veg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 30%;
  position: relative;
}

#tjela-burger {
  object-fit: contain;
  background-image: url(./Assets/tjela-burger.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  position: relative;
}

#tjela-nasi {
  object-fit: contain;
  background-image: url(./Assets/nasicamarao.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  position: relative;
}

#tjela-nasi-frango {
  object-fit: contain;
  background-image: url(./Assets/tjela-nasi-frango.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  position: relative;
}

#tjela-nasi-cogumelos {
  object-fit: contain;
  background-image: url(./Assets/nasicogumelos.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  position: relative;
}

#tjela-green {
  object-fit: contain;
  background-image: url(./Assets/greeny.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  position: relative;
}

#burgerlicious {
  object-fit: contain;
  background-image: url(./Assets/burgerlicious.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 25%;
  position: relative;
}

#avoca {
  object-fit: contain;
  background-image: url(./Assets/avoca.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  position: relative;
}

#pork {
  object-fit: contain;
  background-image: url(./Assets/pork.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 45%;
  position: relative;
}

#cookies {
  object-fit: contain;
  background-image: url(./Assets/cookie.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  position: relative;
}

#potes {
  object-fit: contain;
  background-image: url(./Assets/doces.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  position: relative;
}

#saladafruta {
  object-fit: contain;
  background-image: url(./Assets/saladafruta.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  position: relative;
}

#real {
  object-fit: contain;
  background-image: url(./Assets/tostaabacate.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  position: relative;
}

#salmon {
  object-fit: contain;
  background-image: url(./Assets/crazysalmon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  position: relative;
}

#bennedictjela {
  object-fit: contain;
  background-image: url(./Assets/brunch.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 70%;
  position: relative;
}

#bacon-eggs {
  object-fit: contain;
  background-image: url(./Assets/baconegs.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
  position: relative;
}

#florentine-eggs {
  object-fit: contain;
  background-image: url(./Assets/florentine-eggs.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  position: relative;
}

#mistica {
  object-fit: contain;
  background-image: url(./Assets/tostamistica.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  position: relative;
}

#fluffy {
  object-fit: contain;
  background-image: url(./Assets/fluffy.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 70%;
  position: relative;
}

#simples {
  object-fit: contain;
  background-image: url(./Assets/simples.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 30%;
  position: relative;
}

#berry {
  object-fit: contain;
  background-image: url(./Assets/berry.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  position: relative;
}

#atetebabas {
  object-fit: contain;
  background-image: url(./Assets/atetebabas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  position: relative;
}

#nutjela {
  object-fit: contain;
  background-image: url(./Assets/berry.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 70%;
  position: relative;
}

#acaindoim {
  object-fit: contain;
  background-image: url(./Assets/acaindoim.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 70%;
  position: relative;
}

#vermelho {
  object-fit: contain;
  background-image: url(./Assets/vermelho.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 20%;
  position: relative;
}

#verde {
  object-fit: contain;
  background-image: url(./Assets/verdetox.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  position: relative;
}

#acai {
  object-fit: contain;
  background-image: url(./Assets/acai.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 10%;
  position: relative;
}
#tacos {
  object-fit: contain;
  background-image: url(./Assets/tacos.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

/*#vegan {
	background-image: url(./Assets/leaf.png);
}*/

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #556;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #000;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* POPUP */
.popup-content {
  margin: auto;
  background: #fff;
  width: 90%;
  padding: 15px;
  border-radius: 15px;
}

[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.8);
}

[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.popup-arrow {
  -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  color: #fff;
  stroke-width: 2px;
  stroke: #d7d7d7;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 5px 8px;
  line-height: 20px;
  right: 10px;
  top: 10px;
  font-size: 24px;
  background: #fcb804;
  border-radius: 18px;
  border: none;
}

.header {
  text-align: center;
}

/*  */

.phone {
  position: fixed;
  right: 18px;
  bottom: 48px;
  height: 0px;
  text-align: right;
  z-index: 9999;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.phoneIcon {
  animation: shake 1s 2 cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
